import appConfig from 'config/app.config';

/**
 * 
 * @param {string} userId
 * @returns 
 */
export function checkIfValidUserId(userId) {
	let isValid = true;
	let feedback = null;

	if (!userId || userId.length === 0) {
		/* No input */
		isValid = false;
		feedback = 'emptyUserId';
	} else {
		/* Check format, user id must be e.g. abc1234 */
		if (!userId.match(appConfig.employeeIdRegex)) {
			isValid = false;
			feedback = 'invalidUserId';
		}
	}
	return {isValid, feedback};
}