// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const errorUiTexts = {
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.',
		pl: 'Błąd: nieznany błąd.',
	},

	/* Cookies not accepted */
	cookiesNotAccepted: {
		da: `Du skal godkende brug af cookies for at kunne logge ind.`,
		en: 'You have to accept use of cookies to log in.',
		pl: 'Aby się zalogować, musisz zaakceptować użycie plików cookie.',
	},

	/* Create user (facilitator) */
	emptyFields: {
		da: 'Du skal udfylde alle felter.',
		en: 'Please fill out all fields.',
		pl: 'Proszę wypełnić wszystkie pola.',
	},
	emailExists: {
		da: 'Der findes allerede en bruger med denne email.',
		en: 'This email is already in use.',
		pl: 'Ten email jest już w użyciu.',
	},
	invalidPassword: {
		da: 'Dit password skal være på mindst 8 tegn.',
		en: 'Your password has to be at least 8 characters.',
		pl: 'Twoje hasło musi mieć co najmniej 8 znaków.',
	},
	noPasswordMatch: {
		da: 'Password skal være ens i begge felter.',
		en: 'Passwords do not match.',
		pl: 'Hasła nie pasują do siebie.',
	},

	/* Login / reset password (facilitator) */
	emailNotFound: {
		da: 'Der findes ingen bruger med denne email.',
		en: 'There is no user linked to this email.',
		pl: 'Żaden użytkownik nie jest powiązany z tym e-mailem.',
	},
	invalidEmail: {
		da: 'Din email er ikke gyldig.',
		en: 'This email is not valid.',
		pl: 'Ten e-mail jest nieprawidłowy.',
	},
	invalidEmailPasswordFormat: {
		da: 'Fejl. Check om din email og password er korrekte.',
		en: 'Error. Check if your email and password are correct.',
		pl: 'Błąd. Sprawdź, czy Twój adres e-mail i hasło są poprawne.',
	},
	
	/* Login (player) */
	emptyUserId: {
		da: 'Husk at udfylde dit medarbejder-nr.',
		en: 'Please enter your employee no.',
		pl: 'Proszę podać swój numer pracownika.',
	},
	invalidUserId: {
		da: 'Dit medarbejdernummer skal bestå af 3 bogstaver og 4 tal, f.eks. abc1234.',
		en: 'You employee no. should consist of 3 letters and 4 numbers, e.g. abc1234.',
		pl: 'Twój numer pracownika musi składać się z 3 liter i 4 cyfr, np.: abc1234.',
	}
};

module.exports = {
	errorUiTexts
};