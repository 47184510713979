import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAMeYJNGNuvu-0zyW7_c1SZqFS6_a05MHw',
		authDomain: 'cgl-kmc-test.firebaseapp.com',
		projectId: 'cgl-kmc-test',
		storageBucket: 'cgl-kmc-test.appspot.com',
		messagingSenderId: '479147935267',
		appId: '1:479147935267:web:3aceac78a536645cea9396'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDeHgz9N0wpXx8Ncty9e15XgeBlY_p716c',
		authDomain: 'cgl-kmc-production.firebaseapp.com',
		projectId: 'cgl-kmc-production',
		storageBucket: 'cgl-kmc-production.appspot.com',
		messagingSenderId: '105351703729',
		appId: '1:105351703729:web:50c87fd32f8c0f3c534690'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;