import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './admin-header.scss';


const AdminHeader = ({languageId, userData, goToPage, handleLogout}) => {
	return (
		<div className="AdminHeader">
			
			{/* Logo */ }
			<div className="AdminHeader-logoAndTitle">
				<div className="AdminHeader-logo"><Logo type="white" /></div>
				<div className="AdminHeader-title"><span>{appConfig.name}</span></div>
			</div>

			{/* User info */}
			<div className="AdminHeader-userInfo">
				<span>{getText(facilitatorUiTexts.loggedInAs, languageId)}:</span>
				<span>{userData.email}</span>
			</div>

			{/* Logout btn */}
			<div className="AdminHeader-logoutBtn">
				<Button 
					classes={['logout']} 
					text={getText(loginUiTexts.logout, languageId)} 
					onClick={() => {handleLogout();}} 
				/>
			</div>

			{/* Choose language btn */}
			<div 
				className={'AdminHeader-languageBtn ' + languageId} 
				onClick={() => {goToPage('language');}}
			/>

			{/* Facilitator btn */}
			<div className="AdminHeader-facilitatorBtn">
				<Button
					classes={['default']}
					text={getText(adminUiTexts.facilitatorBtn, languageId)} 
					onClick={() => {goToPage('facilitator');}}
				/>
			</div>
		</div>
	);
};

AdminHeader.propTypes = {
	languageId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default AdminHeader;
