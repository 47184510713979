// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	hygieneTraining: {
		da: 'Hygiejnetræning',
		en: 'Hygiene training',
		pl: 'Szkolenie higieniczne',
		ro: 'Instruire igienă'
	},
	start: {
		da: 'Start',
		en: 'Start',
		pl: 'Początek',
		ro: 'Start'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
		pl: 'Ładowanie',
		ro: 'Se încarcă'
	}
};


module.exports = {
	generalUiTexts
};