const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Velkommen til hygiejnetræningen. Jeg er din leder og hjælper dig gennem spillet. `,
			en: `Welcome to the hygiene training. I'm your manager, and I'll help you through the game. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-high-standards',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Træningen her handler om, hvordan du kan være med til at opretholde vores høje standard. Du skal arbejde med fødevarer, og der er fødevaresikkerhed og kvalitet i højsædet. `,
			en: `This training is about how you can be a part of maintaining our high standards. You'll be working with foodstuffs, so food safety and quality are paramount. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-when-completed',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Når du har gennemført træningen, så kan du hjælpe os med at overholde krav fra myndigheder og vores kunder.`,
			en: `When you've completed the training, you can help us meet demands from the authorities and our clients. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `I spillet skal du løse forskellige opgaver. Og du skal have tre stjerner for at gå videre til anden halvdel af træningen. `,
			en: `In the game you'll be solving different tasks. And you must get three stars to continue to the second part of the training. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-first-assignment',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Første opgave handler om den rigtige påklædning. `,
			en: `The first tasks is about how to dress properly. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-hair-and-beard',
		layout: 's1-m1-hair-and-beard',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: `How should you use hairnet and beard snood? Click on the correct images. `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // Billede af alt hår og ører dækket af hårnet. Ingen skæg. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Billede af alt hår, ører og alt skæg dækket. 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis du ikke har barberet dig inden for de sidste 24 timer, så skal du bruge skægbind i åbne produktzoner.`,
								en: `If you haven't shaved within the last 24 hours, you must use a beard snood in open production zones.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // Noget hår stikker ud. Intet skæg. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Alt hår dækket. Skæg. Ingen skægbind. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // Alt hår men ikke ører dækket. Intet skæg. 
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-jewelry',
		layout: 's1-m3-jewelry',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Der er flere smykker, du ikke må have på i produktionslokaler. Tryk på de fire problemer. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Most jewellery is not allowed in production areas. Click on the 4 issues. Click OK when you are ready, or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glasses', // Briller med smykkesten
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Problemet her er smykkestenene. Du må selvfølgelig gerne have briller på, hvis bare de ikke har sten.`,
								en: `The rhinestones are the issue here. You can, of course, wear glasses, but only without rhinestones. `
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch', // Armbåndsur
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'ring', // Glat vielsesring uden sten
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'necklace' // Halskæde
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-good-hygiene',
		background: 'dressing-room',
		characters: ['manager pose-2'],
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. `,
			en: `Good hygiene begins in the locker room. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-privat-public-clothes',
		background: 'dressing-room',
		characters: ['manager pose-2'],
		text: {
			da: `I omklædningsrummet er der et område til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder. `,
			en: `In the locker room there's a place for private clothes and a place for work clothes. It's important to put things in the right places. `
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-dressing-room-cabinet',
		layout: 's1-m1-dressing-room-cabinet',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 4 ting, som er forkerte på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Click on the 4 issues on this screen. Click OK when you're ready, or if you can't find them all. `,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'workclothes', // Arbejdstøj i skab
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tools', // Værktøj fra produktionen
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Jep, det skal ikke ind i omklædningen`,
								en: `Yes, don't bring that into the locker room. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet', // Blåt hårnet
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig, det skal bare smides ud.`,
								en: `Exactly! Just throw that in the bin. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'nuts', // Pose med hele nødder 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set. Hele nødder er ikke tilladt på matriklen - heller ikke som topping på for eksempel en kage. `,
								en: `Nice catch! Whole nuts are not allowed in the building - not even as topping on, for instance, a cake. `
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-wound',
		layout: 's1-m1-wound',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvad skal du bruge, hvis du har et sår eller en rift?`,
			en: `What should you use if you get a scratch or wound?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Blåt plaster
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Brug kun udleveret blåt plaster - det kan vores metaldetektor spotte. `,
								en: `That's right! You should only use our blue band aids - they can be spotted by our metal detector. `
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Almindeligt beige plaster
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // forbinding
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Gaffertape
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-ready-for-production',
		background: 'dressing-room',
		characters: ['manager pose-1'],
		text: {
			da: `Godt, så er vi ved at være klar til at gå ud i produktionen. Der møder du altid en hygiejnesluse/indgang til hygiejnezone. Lad os lige kigge nærmere på den. `,
			en: `Alright, we are about ready to enter the production areas. On your way there, you'll always pass through a hygiene sluice or entrance. Let's take a closer look at that. `
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m3-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Du skal vaske dine hænder, hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `You are about to wash your hands. What is the correct order of operations when doing that?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med papirhåndklæde`,
					en: `Dry hands with paper towel`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid papirhåndklæde i skraldespand`,
					en: `Toss paper towel in the bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Brug eventuelt håndsprit`,
					en: `Use sanitizer if available`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-nails',
		layout: 's1-m1-nails',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		showRequiredNumberOfAnswers: false,
		subtype: 'images',
		text: {
			da: `Håndhygiejne handler også om neglene. 
				<br /><br /><b>Tryk på de forkerte billeder.</b>`,
			en: `Nails are an important part of hand hygiene.
				<br /><br /><b>Click on the incorret images.</b>`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Korte og rene negle
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // korte med neglelak
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Korte let beskidte negle
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Kunstig negl
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-washing-hands-areas',
		layout: 's1-m1-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		subtype: 'images',
		shuffleOptions: false,
		text: {
			da: `Der er især to områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Two areas are especially easy to forget when washing your hands. What are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-when-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
			<br /><br />
			<b>Vælg 1 - og husk at læse alle mulighederne:</b>`,
			en: `After which situations must you remember to wash your hands?
			<br /><br />
			<b>Choose 1 - and remember to read all the options.</b>`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ved indgang til produktionslokaler`,
					en: `When entering production areas`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`, 
					en: `After breaks`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Når du har rørt ved din mund og næse`,
					en: `After touching your mouth or nose`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Efter rygning`,
					en: `After smoking`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				isCorrect: true,
				text: {
					da: `I alle ovenstående situationer `,
					en: `In all above situations`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-mobile-phone',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2'],
		text: {
			da: `Hvis du må have en mobiltelefon eller tablet med i produktionen, skal den have intakt skærm. Andre personlige ejendele må ikke medtages i lommerne på dit arbejdstøj. `,
			en: `If you are allowed to bring a cell phone or a tablet to the production areas, the screen must be intact. You are not allowed to bring other personal belongings in your work clothes pockets. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-dropping-glasses',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Du må selvfølgelig gerne have briller eller kontaktlinser med, men hvad skal du gøre, hvis du taber dem, eller hvis de går i stykker?`,
			en: `You are, of course, allowed to bring glasses or contacts. But what should you do if you lose them or they break?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true,
				text: {
					da: `Sige det til nærmeste leder`,
					en: `Tell my immediate manager`,
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Udfylde en formular`,
					en: `Fill out a form`,
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Stoppe produktionen`,
					en: `Stop production`,
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Råbe på hjælp`,
					en: `Cry for help`,
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-sandwich',
		layout: 's1-m1-sandwich',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		subtype: 'images',
		text: {
			da: `Hvis du skal transportere for eksempel en sandwich gennem et produktionslokale, hvad er så den rigtige måde at gøre det på?`,
			en: `If you want to transport, say, a sandwhich through a production area, what, then, is the correct way to do it?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Sandwich i lukket emballage
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Og du må selvfølgelig ikke spise af den i produktionslokalerne. `,
								en: `Precisely. And you are obviously not allowed to eat it in a production area. `
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Sandwich på tallerken
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Sandwich i hånd
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Sandwich i emballage som er åben
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-almost-done',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1'],
		text: {
			da: `Du er næsten færdig med første modul. Men inden vi går ind i produktionen, er der et vigtigt spørgsmål om sygdom. `,
			en: `You are almost finished with the first module. But before we enter the production areas, there is an important question about illness.`
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m3-survey-inform-disease',
		shuffleOptions: false,
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `<b>Er du bekendt med følgende?</b>
				<br /><br />Du skal informere din nærmeste leder, hvis du får en fødevareoverført sygdom såsom diarré, opkastning og kraftig influenza. Det samme gælder hvis du har infektioner med betændelse eller sår, eller hvis en fra din husstand har norovirus (roskildesyge).`,
			en: `<b>Are you aware of the following?</b>
			<br /><br />You must inform your immediate supervisor if you contract a foodborne illness such as diarrhea, vomiting, or severe influenza. The same applies if you have infections with inflammation or wounds, or if someone in your household has norovirus (stomach flu).`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, det er jeg klar over`,
					en: `Yes, I am aware of that`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Hvis vi ved den slags, så kan vi bedre sikre en høj hygiejnestandard. Kig eventuelt i hygiejnereglerne for relevante sygdomme. `,
								en: `Good. If we know things like this, we can ensure high hygiene standards. If needed, confirm the hygiene rules for specific ilnesses. `
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, det vidste jeg ikke`,
					en: `No, I did not know that`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt du siger det. Når du er færdig med træningen, så tal straks med en leder, så du kan få styr på det.`,
								en: `It's good that you tell us. After completing this training, immediately talk to a manager about it to make sure you know what you need to. `
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-done-module',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1'],
		text: {
			da: `Sådan! Du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `There you go! You have now completed the first module. If you have three stars, you can proceed to the next module. If not, you have to retake this module.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m3-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};