// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const loginUiTexts = {
	start: {
		da: 'Start',
		en: 'Start',
		pl: 'Początek',
		ro: 'Start'
	},
	login: {
		da: 'Log ind',
		en: 'Log in',
		pl: 'Zaloguj się',
		ro: 'Conectare'
	},
	logout: {
		da: 'Log ud',
		en: 'Log out',
		pl: 'Wyloguj się',
		ro: 'Deconectare'
	},
	player: {
		da: 'Spiller',
		en: 'Player',
		pl: 'Gracz',
		ro: 'Jucător'
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
		pl: 'Moderator',
		ro: 'Facilitator'
	},
	admin: {
		da: 'Administrator',
		en: 'Administrator',
		pl: 'Administrator',
		ro: 'Administrator'
	},
	email: {
		da: 'e-mail',
		en: 'e-mail',
		pl: 'e-mail',
		ro: 'e-mail'
	},
	password: {
		da: 'password',
		en: 'password',
		pl: 'hasło',
		ro: 'parola'
	},
	passwordRepeat: {
		da: 'gentag password',
		en: 'repeat password',
		pl: 'powtórz hasło',
		ro: 'repetați parola'
	},
	userId: {
		da: 'medarbejder-nr.',
		en: 'employee no.',
		pl: 'numer pracownika',
		ro: 'angajat nr.'
	},
	name: {
		da: 'navn',
		en: 'name',
		pl: 'nazwa',
		ro: 'nume'
	},
	loginWithUserId: {
		da: 'Log ind med medarbejder-nr.',
		en: 'Log in with employee no.',
		pl: 'Zaloguj się za pomocą numeru pracownika',
		ro: 'Conectați-vă cu nr. angajat'
	},
	createNewUser: {
		da: 'Opret ny bruger',
		en: 'Create new user',
		pl: 'Utwórz nowego użytkownika',
		ro: 'Creați un utilizator nou'
	},
	forgotPassword: {
		da: 'Glemt dit password?',
		en: 'Forgot your password?',
		pl: 'Nie pamiętasz hasła?',
		ro: 'Ați uitat parola?'
	},
	resetPassword: {
		da: 'Nulstil password',
		en: 'Reset tpassword',
		pl: 'Zresetuj hasło',
		ro: 'Resetați parola'
	},
	goToLoginBtn: {
		da: 'Tilbage til login',
		en: 'Back to login',
		pl: 'Powrót do logowania',
		ro: 'Înapoi la autentificare'
	},
	createUserBtn: {
		da: 'Opret',
		en: 'Create',
		pl: 'Utwórz',
		ro: 'Creați'
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset',
		pl: 'Zresetuj',
		ro: 'Resetați'
	},
	cancel: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		ro: 'Anulați'
	},
	cancel2: {
		da: 'Annuller',
		en: 'Cancel',
		pl: 'Anuluj',
		ro: 'Anulați'
	},
	createUserFeedback: {
		da: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
		en: 'Your new user has been created. Go back to log in to play.',
		pl: 'Twój użytkownik został utworzony. Wróć i zaloguj się, aby grać.',
		ro: 'Contul de utilizator a fost creat. Reveniți la conectare pentru a juca.'
	},
	passwordResetFeedback: {
		da: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
		en: 'You have received a link to reset your password in your inbox.',
		pl: 'Otrzymałeś(-aś) link do zresetowania hasła w swojej skrzynce odbiorczej.',
		ro: 'Ați primit un link în inbox pentru resetarea parolei.'
	}
};




module.exports = {
	loginUiTexts
};