import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import apiHelper from 'helpers/api-helper';
import {getCookie} from 'helpers/cookie-helper';
import {checkIfValidUserId} from 'helpers/login-helper';
import {getText} from 'helpers/language-helper';
import {checkIfValidEmail} from 'helpers/admin-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import LoginPlayer from './login-player';
import Popup from 'components/ui/popup/popup';

class LoginPlayerController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			showConfirmEmployeeIdPopup: false,
			userId: null,
			name: null,
			email: null,
			feedback: null
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			feedback: null
		});
	};

	/**
	 * Show login popup (confirm employee id)
	 * @param {obj} event 
	 * @returns 
	 */
	handleConfirmEmployeeIdPopup = (event = null) => {
		if (event) event.preventDefault();
		if (this.state.isLoading) return;

		/* Check if cookies are accepted */
		const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent || cookieConsent.length === 0) {
			this.setState({feedback: getText(errorUiTexts.cookiesNotAccepted, this.props.languageId)});
			return;
		}

		/* Check if user id is valid */
		const userId = this.state.userId;
		const {isValid, feedback} = checkIfValidUserId(userId);

		if (!isValid) {
			/* User id is not valid */
			this.setState({feedback: getText(errorUiTexts[feedback], this.props.languageId)});
		} else {
			/* User id valid */
			this.setState({showConfirmEmployeeIdPopup: true});
		}

	};

	/**
	 * Hide / show login popup
	 * @param {bool} showConfirmEmployeeIdPopup 
	 */
	toggleConfirmEmployeeIdPopup = (showConfirmEmployeeIdPopup) => {
		this.setState({showConfirmEmployeeIdPopup: showConfirmEmployeeIdPopup});
	};

	/**
	 * Start login process
	 */
	handleLoginWithEmployeeId = (event) => {
		if (event) event.preventDefault();
		if (this.state.isLoading) return;

		/* Close login popup */
		this.setState({showConfirmEmployeeIdPopup: false});

		/* Check if cookies are accepted */
		const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent || cookieConsent.length === 0) {
			this.setState({feedback: getText(errorUiTexts.cookiesNotAccepted, this.props.languageId)});
			return;
		}

		/* Check if user id is valid */
		const userId = this.state.userId;
		const {isValid, feedback} = checkIfValidUserId(userId);
		
		if (!isValid) {
			/* User id is not valid */
			this.setState({feedback: getText(errorUiTexts[feedback], this.props.languageId)});
		} else {
			/* User id is valid */
			const lowerCaseUserId = userId.toLowerCase();
			this.setState({isLoading: true, feedback: null}, () => {
				const db = firebase.firestore();
				db.collection(appConfig.playersDbName).doc(lowerCaseUserId).get().then((doc) => {
					if (doc.exists) {
						/* Player already exists, get login token and login */
						this.handleGetLoginToken(lowerCaseUserId);
					} else {
						/* Player does not exist, create player and then get login token and login */
						db.collection(appConfig.playersDbName).doc(lowerCaseUserId).set({
							created: dayjs(new Date()).format('YYYY-MM-DD'),
							lastLogin: dayjs(new Date()).format('YYYY-MM-DD')
						}).then(() => {
						/* Player created, get login token and login */
							this.handleGetLoginToken(lowerCaseUserId);
						}).catch((error) => {
							console.error('Error creating player: ', error);
							this.setState({
								isLoading: false, 
								feedback: getText(errorUiTexts.unknownError, this.props.languageId)
							});
						});
					}
				}).catch((error) => {
					console.error(error);
					this.setState({
						isLoading: false, 
						feedback: getText(errorUiTexts.unknownError, this.props.languageId)
					});
				});	
			});
		}
	};

	/**
	 * Login with name & email
	 * @returns 
	 */
	handleLoginWithNameEmail = (event) => {
		if (event) event.preventDefault();
		if (this.state.isLoading) return;

		if (
			!this.state.name || this.state.name.length === 0 || 
			!this.state.email || this.state.email.length === 0
		) {
			/* Error: empty fields */
			this.setState({
				isLoading: false,
				feedback: getText(errorUiTexts.emptyFields, this.props.languageId)
			});
		} else {
			const {isValidEmail} = checkIfValidEmail(this.state.email);
			if (!isValidEmail) {
				/* Error: invalid email */
				this.setState({
					isLoading: false,
					feedback: getText(adminUiTexts.addFacilitatorToGamePopup.invalidEmail, this.props.languageId)
				});				
			} else {
				/* Input ok */
				const lowerCaseEmail = this.state.email.toLowerCase();
				this.setState({isLoading: true, feedback: null}, () => {
					const db = firebase.firestore();
					db.collection(appConfig.playersDbName).doc(lowerCaseEmail).get().then((doc) => {
						if (doc.exists) {
							/* Player already exists, get login token and login */
							this.handleGetLoginToken(lowerCaseEmail);
						} else {
							/* Player does not exist, create player and then get login token and login */
							db.collection(appConfig.playersDbName).doc(lowerCaseEmail).set({
								name: this.state.name,
								created: dayjs(new Date()).format('YYYY-MM-DD'),
								lastLogin: dayjs(new Date()).format('YYYY-MM-DD')
							}).then(() => {
							/* Player created, get login token and login */
								this.handleGetLoginToken(lowerCaseEmail);
							}).catch((error) => {
								console.error('Error creating player: ', error);
								this.setState({
									isLoading: false, 
									feedback: getText(errorUiTexts.unknownError, this.props.languageId)
								});
							});
						}
					}).catch((error) => {
						console.error(error);
						this.setState({
							isLoading: false, 
							feedback: getText(errorUiTexts.unknownError, this.props.languageId)
						});
					});	
				});
			}
		}
	};

	/**
	 * Get a login token for player
	 * Call firebase auth to sign in with that token.
	 * @param {number} userId 
	 */
	handleGetLoginToken = (userId) => {
		apiHelper('player/join-game', {userId: userId}).then(
			(response)=>{
				if (response.status === 'success' && response.token) {
					this.loginWithToken(response.token);
				} else {
					console.error(response);
					this.setState({
						isLoading: false,
						feedback: getText(errorUiTexts.unknownError, this.props.languageId)
					});
				}
			},
			(rejection) => {
				console.error('rejection: ', rejection);
				this.setState({
					isLoading: false,
					feedback: getText(errorUiTexts.unknownError, this.props.languageId)
				});
			}
		);
	};


	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
			// All okay, should trigger the authStateChanged in LoginController
			})
			.catch((error) => {
				console.error('sign in error', error);
				this.setState({
					feedback: getText(errorUiTexts.unknownError, this.props.languageId),
					isLoading: false,
					userId: null
				});
			});
	};

	render = () => {
		return (
			<>
				<LoginPlayer
					isLoading={this.state.isLoading}
					languageId={this.props.languageId}
					playerLoginType={this.props.playerLoginType}
					userId={this.state.userId}
					name={this.state.name}
					email={this.state.email}
					feedback={this.state.feedback}
					handleInput={this.handleInput}
					handleLoginWithNameEmail={this.handleLoginWithNameEmail}
					handleConfirmEmployeeIdPopup={this.handleConfirmEmployeeIdPopup}
				/>
				{this.state.showConfirmEmployeeIdPopup && <Popup 
					type='login'
					title={this.state.userId} 
					text= {getText(playerUiTexts.workerId, this.props.languageId)}
					togglePopup={() => {this.toggleConfirmEmployeeIdPopup(false);}}
					buttons={[
						{
							classes: ['green'],
							text: getText(playerUiTexts.yes, this.props.languageId),
							action: () => {this.handleLoginWithEmployeeId();}
						},
						{
							classes: ['red'],
							text: getText(playerUiTexts.no, this.props.languageId),
							action: () => {this.toggleConfirmEmployeeIdPopup(false);}
						}
					]}
				/>}
			</>
		);
	};
}

LoginPlayerController.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerLoginType: PropTypes.string.isRequired
};

export default LoginPlayerController;