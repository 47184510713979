const moduleImages = [
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-1.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-2.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-3.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-4.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-private-locker/option-5.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-work-locker/option-1.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-work-locker/option-2.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-work-locker/option-3.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-work-locker/option-4.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-work-locker/option-5.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-work-locker/option-6.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-dressing-room/option-1.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-dressing-room/option-2.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-dressing-room/option-3.svg',
	'modules/tasks/multiple-choice/s1-m3-items-not-in-dressing-room/option-4.svg',
	'modules/tasks/multiple-choice/s1-m3-lunch-items/option-1.svg',
	'modules/tasks/multiple-choice/s1-m3-lunch-items/option-2.svg',
	'modules/tasks/multiple-choice/s1-m3-lunch-items/option-3.svg',
	'modules/tasks/multiple-choice/s1-m3-lunch-items/option-4.svg',
	'modules/tasks/multiple-choice/s1-m3-lunch-items/option-5.svg',
	'modules/tasks/multiple-choice/s1-m3-lunch-items/option-6.svg',
	'modules/tasks/multiple-choice/s1-m3-hands/background.svg',

	'modules/tasks/spot-errors/s1-m3-clothes/background.svg',
];

export {
	moduleImages
};