const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		showProgressBar: false,
		background: 'production',
		characters: ['manager pose-1 hairnet-1 hairnet-1'],
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Før handlede det om påklædning og forberedelse, nu skal det handle om hygiejne i selve produktionen. `,
			en: `Welcome to second part of the hygiene training. The first module was about how to dress and prepare, now we'll talk about hygiene in the production areas. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-extra-important',
		showProgressBar: false,
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Her går tingene stærkt, og derfor er det ekstra vigtigt at være opmærksom på hygiejnen. Ellers kan vi ikke garantere vores sædvanlige kvalitet og fødevaresikkerhed. `,
			en: `Things move fast in here, so it's extra important to be aware of hygiene. If not, we can't guarantee our usual high standards of quality and food safety. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stars',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre træningen. `,
			en: `Let's begin! Remember, you must get three stars to complete the training. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-extra-perceptive',
		background: 'production',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why must we be extra aware of hygiene in production areas?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Because of legal requirements`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because bad hygiene can make people sick`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To maintain high quality`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all above reasons`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-remember-ingredients',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Husk: vi arbejder med ingredienser, der kan blive til både mad og dyrefoder. Derfor er fødevaresikkerhed og hygiejne ekstremt vigtigt. `,
			en: `Remember, we work with ingredients that can become food for people or animals. So food safety and hygiene is extremely important. `
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-clothes',
		layout: 's1-m2-clothes',
		background: 'production',
		text: {
			da: `Lad os lige se på påklædning igen. Tryk på de 4 fejl, som er på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Let's take a look at clothing again. Click on the 4 issues. Click OK when you are done, or if you can't find them all. `,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pen' // Kuglepen i kraven
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirty-clothes' // Beskidt tøj
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch' // Armbåndsur
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet' // Hårnet oppe over ørene
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-production-room',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Nu skal vi kigge nærmere på produktionslokalet. På det næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Now we'll take a closer look at the production areas. On the next screen, see if you can spot the things that can cause problems when it comes to hygiene. `
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-errors',
		layout: 's1-m2-production-errors',
		background: 'production',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Click on the 5 issues. Click OK when you are done, or if you can't find them alle. `,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hose' // Vandslange ligger på gulvet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'lamp' // En lampe lyser ikke og har hul
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove' // Møtrik eller handske på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'red-tool' // Rødt værktøj hænger forkert
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'fine' // Sigtesold på gulvet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-why-problem',
		background: 'production',
		text: {
			da: `I forrige opgave var det en fejl, at sigtesolden stod på gulvet. Hvorfor er det et problem i forhold til hygiejne?`,
			en: `In the previous task, it was an issue that the strainer was placed on the floor. Why is that a hygiene issue?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Sigtesolden har produktkontakt og kan blive kontamineret`,
					en: `The strainer is in direct contat with product and can become contaminated`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Der er risiko for, at forbrugerne bliver syge`, 
					en: `There is a risk of consumers getting sick`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Primært fordi der kan komme fremmedlegemer i produktet, men også fordi det kan blive forurenet på anden vis.`,
								en: `Yes! Primarily beause it can cause foreign bodies to enter our products. But this can also lead to other kinds of contamintion. `
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Fordi det tiltrækker skadedyr`,
					en: `Because it attracts pests`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fordi der kan gå hul på den`,
					en: `Because it can be damaged`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Og hvis der går hul på den, så er der større chance for, at der kommer fremmedlegemer i vores produkter. `,
								en: `Exactly. And if it gets broken, there's a bigger chance of foreign bodies entering our products.  `
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Fordi den kan lække olie ud på gulvet`,
					en: `Because it can leak oil onto the floor`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-close-to-production',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Du er tæt på produktionen hver dag. Derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du ser en fejl eller afvigelse, har du pligt til at indberette det til nærmeste leder. `,
			en: `You are close to production every day. So you probably notice things others might overlook. And if you see a mistake or a deviation, it's your duty to report it to your immediate manager. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der. `,
			en: `Now, let's talk about contamination. That is when things get into our products that aren't supposed to be there. `
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-broken-lamp',
		background: 'production',
		text: {
			da: `En lampe går i stykker i produktionen tæt på åben produktzone. Der er risiko for, der er kommet fremmedlegemer i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp gets broken in a production area close to an open product zone. There's a risk that foreign bodies have ended up in our products. What should you do? Place the actions below in the correct order. `
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Stop produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tilkald nærmeste leder`,
					en: `Call for immediate manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Oprydning med gul kost og gul spand`,
					en: `Clean up with yellow broom and yellow bucket`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Skift arbejdstøj og tjek sko for glasskår`,
					en: `Change work clothes and check shoes for glass shards`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					da: `Afvent frigivelse fra leder`,
					en: `Await release from manager`
				},
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`
				},
				correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-our-products-can-contaminate',
		background: 'production',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can be contaminated in different ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det kan være farligt for kunden`,
					en: `It can be dangerous to the consumer`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can ruin the taste`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Det vil for eksempel kunne ske, hvis produkterne bliver forurenet kemisk eller mikrobiologisk. `,
								en: `Yes! That could be the case if products get chemically or microbiologically contaminated.  `
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can create unhappy customers`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis Kvalitet ser det`,
					en: `Contamination is only an issue if Quality sees it`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination-allergy',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Forurening kan være et problem for folk med allergi. Hvis der for eksempel kommer peanuts i vores produkter, så kan det fremkalde et anfald hos kunder, der er allergiske over for dem. `,
			en: `Contamination can be a problem for people with allergies. If, for instance, peanuts get into our products, it can cause an allergic reaction with people who have allergies. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-never-bring-this',
		layout: 's1-m2-never-bring-this',
		background: 'production',
		subtype: 'images',
		text: {
			da: `Klik på det du ALDRIG må have med på vores fabrikker.`,
			en: `Click on the things that you may NEVER bring to our factories. `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Hele nødder og nøddetoppings
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hvedemel
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Jordbær
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Æbler
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Ananas
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-dont-eat-hygienezones',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Det er også vigtigt at huske, at du ikke må spise i hygiejnezoner. Det gælder også slik og tyggegummi. `,
			en: `It's also important to remember that you are not allowed to eat in hygiene zones. This includes candy and chewing gum. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-drinks-operator-room',
		layout: 's1-m2-drinks-operator-room',
		background: 'production',
		subtype: 'images',
		text: {
			da: `Hvis du skal have drikkevarer med ind i operatørrummet, hvordan skal det så transporteres?`,
			en: `If you need to bring beverages to the operating room, how should you transport them?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Termokande
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Drikkedunk
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Krus
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Kop med underkop
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Øl-hjelm med sugerør
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-chemical-contamination',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Vores produkter kan også blive kemisk forurenet. `,
			en: `Our products can also be contaminated chemically.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-wrongly-marked-bottles',
		layout: 's1-m2-wrongly-marked-bottles',
		background: 'production',
		subtype: 'images',
		text: {
			da: `Hvilke af følgende dunke er ikke korrekt markeret? `,
			en: `Which of the following jugs is not marked correctly?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Dunk uden mærkat
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Dunk med ulæselig mærkat
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Korrekt markeret dunk
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-food-safety',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Af hensyn til fødevaresikkerheden må der ikke være uvedkommende på fabrikken. Så sørg for at holde døre og porte lukkede. `,
			en: `For the sake of food safety, unauthorized persons are not allowed in the factory. So make sure to keep doors and gates closed.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stranger-danger',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Uvedkommende har ikke din træning, og derfor kan de ved et uheld forurene vores produkter. Og i værste fald kan uvedkommende også bevidst ønske at sabotere os. `,
			en: `Unauthorised persons don't have your training and can accidentially contaminate our products. And, in worst case scenarios, unauthorised persons can be looking to sabotage us. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-stranger-alone-danger',
		background: 'production',
		text: {
			da: `Hvad gør du, hvis du møder en, du ikke kender og som færdes alene på fabrikken?`,
			en: `What should you if you meet someone you don't know walking around unaccompanied in the factory?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Beder dem om identifikation`,
					en: `Ask for identification`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du må også gerne informere nærmeste leder bagefter. `,
								en: `Also, please inform you immediate manager afterwards`
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Følger dem ud, hvis de ikke har gyldig grund til at være her`,
					en: `Walk them to the exit if they don't have a valid reason to be here`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Ikke noget, de må sikkert gerne være her`,
					en: `Nothing, I guess they are allowed to be here`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Ringer efter politiet`,
					en: `Call the police`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-almost-last-assignments',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Nu er du snart nået til de sidste opgaver i modulet. `,
			en: `You have almost reached the final tasks in the module. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-big-issues-pests',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Vi mangler en sidste ting. Et af de helt store problemer i forhold til hygiejne er, hvis der er skadedyr.`,
			en: `But one last thing. One of the big problems when it comes to hygiene is pests. `
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse!',
		background: 'production',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in a production area. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while calling for help`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`,
					en: `Try to catch the mouse, and then call for help if I can't`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell my immediate manager at the end of the shift`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-pest-signs',
		background: 'production',
		text: {
			da: `Hvad hvis du ser tegn på skadedyr - for eksempel efterladenskaber fra en mus? Hvad skal du så gøre?`,
			en: `What if you see signs of pests - eg. mouse droppings. What should you then do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Sige det personligt til nærmeste leder`,
					en: `Tell my immediate manager in person`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Hente en kat`,
					en: `Get a cat`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til en kollega`,
					en: `Tell a colleague`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-gates',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Hvis du holder døre og porte lukkede, så hjælper det også til at holde skadedyr ude. `,
			en: `Keeping doors and gates closed helps to keep out pests. `
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-four-questions',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Til allersidst er der fire spørgsmål om vores fødevaresikkerhedskultur. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme. `,
			en: `Finally, we have four questions about our food safety culture. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously. `
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-1',
		shuffleOptions: false,
		background: 'production',
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg har lov og pligt til at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br />< br/>I have authority and responsibility to react if I spot something that can be a hazard to food safety.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-2',
		shuffleOptions: false,
		background: 'production',
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-3',
		shuffleOptions: false,
		background: 'production',
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />If I see a food safety issue, I'll either solve it or inform my immediate manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-4',
		shuffleOptions: false,
		background: 'production',
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />If I report a food safety risk, something will be done about it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-done-module',
		background: 'production',
		characters: ['manager pose-1 hairnet-1'],
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module. `
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};