let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let usersDbName = 'users';
let gamesDbName = 'games';
let playersDbName = 'players';
let surveysDbName = 'surveys';
let minSurveyParticipants = 1;
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-kmc-test.web.app';
	apiURL = 'https://cgl-kmc-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-kmc-demo.web.app';
		apiURL = 'https://cgl-kmc-demo.web.app/api/';
		gamesDbName = 'games-demo';
		playersDbName = 'players-demo';
		surveysDbName = 'surveys-demo';
		minSurveyParticipants = 5;
	}
}
if (env === 'production') {
	gameUrl = 'kmchygienetraining.dk';
	apiURL = 'https://cgl-kmc-production.web.app/api/';
	minSurveyParticipants = 5;
}



const appConfig = {
	env,
	usersDbName,
	gamesDbName,
	playersDbName,
	surveysDbName,
	gameUrl,
	apiURL,	
	name: 'KMC',
	faclitatorSectionUri: 'facilitator',
	cookiesAcceptedCookieName: 'kmc_cookies_accepted',
	screenBaseWidth: 1920,
	defaultLanguage: 'da',
	employeeIdRegex: /^[A-Za-z]{3}[0-9]{4}$/,
	maxStarsPerModule: 3,
	inactivityLimitSeconds: 30,
	moduleDisabledDays: 7,
	minSurveyParticipants
};

export default appConfig;