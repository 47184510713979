const moduleImages = [	
	'modules/tasks/image/s1-m1-private-locker-items.svg',

	'modules/tasks/multiple-choice/s1-m1-hair-and-beard/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-and-beard/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-and-beard/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-and-beard/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-hair-and-beard/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-6.svg',
	'modules/tasks/multiple-choice/s1-m1-private-locker-items/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-private-locker-items/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-private-locker-items/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-private-locker-items/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-private-locker-items/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-private-locker-items/option-6.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-5.svg',
	
	'modules/tasks/spot-errors/s1-m1-dressing-room-locker/background.svg',
];

export {
	moduleImages
};