import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getGameUrl} from 'helpers/game-helper';
import {getText} from 'helpers/language-helper';
import {generalUiTexts} from 'data/ui-texts/general-ui-texts';
import Logo from 'components/ui/logo/logo';
import LoginFacilitatorController from './login-facilitator/login-facilitator-controller';
import CreateUserController from './create-user/create-user-controller';
import LoginPlayerController from './login-player/login-player-controller';
import ResetPassword from './reset-password/reset-password';
import CookieConsent from 'components/ui/cookie-consent/cookie-consent';
import './login.scss';

const Login = ({languageId, playerLoginType, gameData}) => {
	/* Get active box component for facilitator box */
	let [facilitatorComponentType, setFacilitatorComponentType] = useState('login');
	
	/* Get game url */
	const gameUrl = getGameUrl(window.location.pathname);

	/* Get login component */
	let LoginComponent = null; 

	if (gameUrl === appConfig.faclitatorSectionUri) {
		/* Facilitator login */
		LoginComponent = LoginFacilitatorController;
		if (facilitatorComponentType === 'createUser') LoginComponent = CreateUserController;
		if (facilitatorComponentType === 'resetPassword') LoginComponent = ResetPassword;
	} else {
		if (gameData) {
			LoginComponent = LoginPlayerController;
		}
	}

	return (
		<div className="Login">
			{/* Logo */}
			<div className="Login-logo"><Logo /></div>
			<div className="Login-title"><span>{getText(generalUiTexts.hygieneTraining, languageId)}</span></div>
			
			{/* Login */}
			<div className='Login-loginComponent'>
				{LoginComponent && <LoginComponent 
					languageId={languageId} 
					playerLoginType={playerLoginType}
					setFacilitatorComponentType={setFacilitatorComponentType} 
				/>}
			</div>

		
			{/* Cookies */}
			<CookieConsent languageId={languageId} page="login" />

			{/* CGL logo */}
			<div className="Login-logoCGL"><Logo type="cgl"/></div>			
		</div>
	);
};

Login.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerLoginType: PropTypes.string,
	gameData: PropTypes.object
};

export default Login;