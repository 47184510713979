import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {scenariosData} from 'data/scenarios-data';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './facilitator-header.scss';


const FacilitatorHeader = (props) => {
	const {
		languageId, 
		numberOfGames, 
		userData, 
		gameData, 
		goToPage, 
		handleSelectGame, 
		handleLogout
	} = props;

	let gameScenarioName = null;
	if (gameData) {
		const scenarioId = (gameData && gameData.scenarioId ? gameData.scenarioId : 'scenario-1');
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
		if (scenarioData) gameScenarioName = getText(scenarioData.title, languageId);
	}


	return (
		<div className="FacilitatorHeader">
			
			{/* Logo */ }
			<div className="FacilitatorHeader-logoAndTitle">
				<div className="FacilitatorHeader-logo"><Logo type="white" /></div>
				<div className="FacilitatorHeader-title"><span>{appConfig.name}</span></div>
				{gameScenarioName && 
					<div className="FacilitatorHeader-subtitle"><span>{gameScenarioName}</span></div>
				}
			</div>
			
			{/* Game info */}
			{gameData && <div className="FacilitatorHeader-gameInfo">
				<div className="FacilitatorHeader-gameName">
					<span>{getText(facilitatorUiTexts.gameName, languageId)}:</span>
					<span>{gameData.name}</span>
				</div>
				<div className="FacilitatorHeader-gameUrl">
					<span>{getText(facilitatorUiTexts.gameUrl, languageId)}:</span>
					<span>{appConfig.gameUrl + '/' + gameData.url}</span>
				</div>
				{numberOfGames > 1 && <div className="FacilitatorHeader-backBtn">
					<Button 
						classes={['default']}
						text={getText(facilitatorUiTexts.backToChooseGames, languageId)}
						onClick={() => {handleSelectGame(null);}}
					/>
				</div>}
			</div>}

			{/* User info */}
			<div className="FacilitatorHeader-userInfo">
				<span>{getText(facilitatorUiTexts.loggedInAs, languageId)}:</span>
				<span>{userData.email}</span>
			</div>

			{/* Logout btn */}
			<div className="FacilitatorHeader-logoutBtn">
				<Button 
					classes={['logout']} 
					text={getText(loginUiTexts.logout, languageId)} 
					onClick={() => {handleLogout();}} 
				/>
			</div>

			{/* Choose language btn */}
			<div 
				className={'FacilitatorHeader-languageBtn ' + languageId} 
				onClick={() => {goToPage('language');}}
			/>

			{/* Admin btn */}
			{userData.role === 'admin' && <div className="FacilitatorHeader-adminBtn">
				<Button
					classes={['default']}
					text={getText(facilitatorUiTexts.admin, languageId)} 
					onClick={() => {goToPage('admin');}}
				/>
			</div>}
		</div>
	);
};

FacilitatorHeader.propTypes = {
	languageId: PropTypes.string.isRequired,
	numberOfGames: PropTypes.number,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	goToPage: PropTypes.func.isRequired,
	handleSelectGame: PropTypes.func,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorHeader;
