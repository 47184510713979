const moduleImages = [
	'modules/tasks/multiple-choice/s1-m2-protect-clothes/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-protect-clothes/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-protect-clothes/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-protect-clothes/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-protect-clothes/option-5.svg',
	'modules/tasks/multiple-choice/s1-m2-protect-clothes/option-6.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-4.svg',
	
	'modules/tasks/spot-errors/s1-m2-production-hygiene-issues/background.svg',
	'modules/tasks/spot-errors/s1-m2-production-issues/errors-1.svg',
	'modules/tasks/spot-errors/s1-m2-production-issues/errors-2.svg',
];

export {
	moduleImages
};