// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const playerUiTexts = {
	/* Login */
	workerId: {
		da: 'Er dette dit medarbejdernummer?',
		en: 'Is this your employee number?',
		pl: 'Czy to Twój numer pracownika?',
		ro: 'Acesta este numărul dvs. de angajat?'
	},
	yes: {
		da: 'Ja',
		en: 'Yes',
		pl: 'Tak',
		ro: 'da'
	},
	no: {
		da: 'Nej',
		en: 'No',
		pl: 'Nie',
		ro: 'Nu'
	},

	/* Lobby */
	noGame: {
		da: 'Der findes ikke noget spil under denne URL',
		en: 'There is no game connected to this URL',
		pl: 'Z tym adresem URL nie jest powiązana żadna gra',
		ro: 'Nu există niciun joc conectat la această adresă URL'
	},
	playerDiffGameConfirmId: {
		da: `Det ser ud til dit medarbejdernummer %userId% hører til på %gameName%. 
			<br /><br />Er du sikker på, det er dit medarbejdernummer?`,
		en: `It looks like you employee id %userId% is connected to %gameName%.
			<br /><br />Are you sure it is your employee id?`,
		pl: `Wygląda na to, że Twój numer pracownika %userId% jest połączony z %gameName%. 
			<br /><br />Czy na pewno jest to Twój numer pracownika?`,
		ro: `Se pare că ID-ul dvs. de angajat %userId% este conectat la %gameName%.
			<br /><br />Sigur acesta este ID-ul dvs. de angajat?`
	},	
	noLogOut : {
		da: 'Nej, log ud',
		en: 'No, log out',
		pl: 'Nie, wyloguj się',
		ro: 'Nu, deconectați-vă'
	},
	playerDiffGame: {
		da: `Du er allerede i gang med %gameName%.
			<br/><br/>Du kan kun være i gang med ét spil ad gangen.`,
		en: `You are currently connected to %gameName%.
			<br /><br />You can only be connected to one game at a time.`,
		pl: `Jesteś obecnie połączony(-a) z %gameName%. 
			<br /><br />W danym momencie możesz być połączony(-a) tylko z jedną grą.`,
		ro: `Momentan sunteți conectat la %gameName%.
			<br /><br />Vă puteți conecta la un singur joc odată`
	},
	gameDeleted: {
		da: '??? (slettet)',
		en: '??? (deleted)',
		pl: '??? (usunięte)',
		ro: '??? (șters)'
	},
	goToGameBtn: {
		da: 'Gå til %gameName%',
		en: 'Go to %gameName%',
		pl: 'Przejdź do %gameName%',
		ro: 'Accesați %gameName%'
	},
	switchGameBtn: {
		da: 'Skift til %gameName%',
		en: 'Switch to %gameName%',
		pl: 'Przełącz na %gameName%',
		ro: 'Comutați la %gameName%'
	},
	switchGameWarning: {
		da: `Du kan kun være i gang med ét spil ad gangen.
			<br /><br/>Hvis du skifter til %gameName2% vil al din data for %gameName1% blive slettet.
			<br /><br/>Er du sikker på, du vil skifte til %gameName2%?`,
		en: `You can only be connected to one game at a time.
			<br /><br />If you switch to %gameName2% all your progress in %gameName1% will be lost.
			<br /><br />Are you sure you want to switch to %gameName2%?`,
		pl: `Możesz być połączony(-a) tylko z jedną grą na raz.
			<br /><br />Jeśli przełączysz się na %gameName2%, wszystkie Twoje postępy w %gameName1% zostaną utracone.
			<br /><br />Czy na pewno chcesz przełączyć się na %gameName2%?`,
		ro: `Vă puteți conecta la un singur joc odată.
			<br /><br />Dacă treceți la %gameName2%, tot progresul dvs. din %gameName1% va fi pierdut.
			<br /><br />Sigur doriți să treceți la %gameName2%?`
	},
	cancelBtn: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		ro: 'Anulați'
	},
	confirmBtn: {
		da: 'Ja, skift spil',
		en: 'Yes, switch game',
		pl: 'Tak, zmień grę',
		ro: 'Da, schimbați jocul'
	},

	/* Choose language */
	chooseLanguage: {
		da: 'Vælg sprog',
		en: 'Choose language',
		pl: 'Wybierz język',
		ro: 'Selecteaza limba'
	},

	/* Player info */
	playerInfo: {
		da: 'Indtast dit navn og e-mail for at spille.',
		en: 'Enter your name and e-mail to player.',
		pl: 'Wpisz swoją nazwę i adres e-mail do gracza.',
		ro: 'Introduceți numele și adresa de e-mail a jucătorului.'
	},
	playerInfoName: {
		da: 'Navn',
		en: 'Name',
		pl: 'Nazwa',
		ro: 'Nume'
	},
	playerInfoEmail: {
		da: 'E-mail',
		en: 'E-mail',
		pl: 'E-mail',
		ro: 'E-mail'
	},


	/* Module overview */
	reset: {
		da: 'Reset',
		en: 'Reset',
		pl: 'Zresetuj',
		ro: 'Resetați'
	},

	moduleOverview: {
		da: 'Moduloversigt',
		en: 'Module overview',
		pl: 'Przegląd modułów',
		ro: 'Prezentare generală a modulului'
	},
	employeeNumber: {
		da: 'Medarbejder-nr.',
		en: 'Employee no.',
		pl: 'Numer pracownika',
		ro: 'Angajat nr.'
	},

	/* Module - general */
	continue: {
		da: 'Videre',
		en: 'Next',
		pl: 'Następny',
		ro: 'Următorul'
	},
	ok: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		ro: 'OK'
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
		pl: 'Wybierz',
		ro: 'Alegeți'
	},
	option: {
		da: 'svar',
		en: 'option',
		pl: 'opcja',
		ro: 'opțiune'
	},
	options: {
		da: 'svar',
		en: 'options',
		pl: 'opcje',
		ro: 'opțiuni'
	},
	finishBtn: {
		da: 'Afslut',
		en: 'Finish',
		pl: 'Zakończ',
		ro: 'Finalizați'
	},

	/* Module - results */
	results: {
		da: 'resultat',
		en: 'results',
		pl: 'wyniki',
		ro: 'rezultate'
	},
	stars: {
		da: 'Stjerner',
		en: 'Stars',
		pl: 'Gwiazdki',
		ro: 'Stele'
	},
	points: {
		da: 'Point',
		en: 'Points',
		pl: 'Punkty',
		ro: 'Puncte'
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
		pl: 'Spędzony czas',
		ro: 'Timpul necesar'
	},
	avgErrors: {
		da: 'Antal fejl per opgave (gennemsnit)',
		en: 'Average mistakes per task',
		pl: 'Średnia liczba błędów na zadanie',
		ro: 'Numărul mediu de greșeli pe activitate'
	},
	noErrorsTasks: {
		da: 'Antal opgaver løst uden fejl',
		en: 'Number of tasks solved without mistakes',
		pl: 'Liczba zadań rozwiązanych bez błędów',
		ro: 'Numărul de activități rezolvate fără greșeli'
	},
	backToModuleOverview: {
		da: 'Tilbage til moduloversigt',
		en: 'Back to module overview',
		pl: 'Powrót do przeglądu modułów',
		ro: 'Înapoi la prezentarea generală a modulului'
	},
	tryAgain: {
		da: 'Prøv igen',
		en: 'Try again',
		pl: 'Spróbuj ponownie',
		ro: 'Încercați din nou'
	},
	confirmTryAgainPopup: {
		title: {
			da: 'Prøv igen',
			en: 'Try again'	,
			pl: 'Spróbuj ponownie',
			ro: 'Încercați din nou'
		},
		text: {
			da: 'Vil du prøve at slå din egen score? Du kan nulstille modulet og spille igen.',
			en: 'Want to try to beat your own score? You can reset the module and play again.',
			pl: 'Chcesz spróbować pobić swój własny wynik? Możesz zresetować moduł i grać ponownie.',
			ro: 'Vreți să încercați să vă depășiți scorul? Puteți să resetați modulul și și să jucați din nou.'
		},
		resetBtn: {
			da: 'Nulstil',
			en: 'Reset',
			pl: 'Zresetuj',
			ro: 'Resetați'
		},
		cancelBtn: {
			da: 'Afbryd',
			en: 'Cancel',
			pl: 'Anuluj',
			ro: 'Anulați'
		}
	},

	/* Module - survey task */
	surveyChooseXOptions: {
		da: 'Vælg %X%',
		en: 'Choose %X%',
		pl: 'Wybierz %X%',
		ro: 'Alegeți %X%'
	},
	surveyChooseMaxXOptions: {
		da: 'Vælg op til %X%',
		en: 'Choose up to %X%',
		pl: 'Wybierz do %X%',
		ro: 'Alegeți până la %X%'
	},

	/* Module - order task */
	orderPopup: {
		title: {
			da: 'Du fik %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
			pl: 'Masz %correctAnswers%/%totalAnswers%.',
			ro: 'Ați primit %correctAnswers%/%totalAnswers%'
		},
		text: {
			da: 'Prøv igen!',
			en: `Try again!`,
			pl: 'Spróbuj ponownie!',
			ro: 'Încercați din nou!'
		}
	},

	/* Module - spot errors task */
	spotErrorsPopup: {
		title1: {
			da: 'Ups!',
			en: 'Oops!',
			pl: 'Ups!',
			ro: 'Hopa!'
		},
		text1: {
			da: 'Du fandt kun %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You only %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znaleziono tylko %spottedErrors% z %numberOfErrors% błędów.',
			ro: 'Aveți doar %spottedErrors% din %numberOfErrors% erori.'
		},
		title2: {
			da: 'Ikke dårligt!',
			en: 'Not bad!',
			pl: 'Nieźle!',
			ro: 'Nu-i rău!'
		},
		text2: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znaleziono %spottedErrors% z %numberOfErrors% błędów.',
			ro: 'Ați găsit %spottedErrors% din %numberOfErrors% erori.'
		},
		title3: {
			da: 'Godt klaret!',
			en: 'Well done!',
			pl: 'Dobra robota!',
			ro: 'Bravo!'
		},
		text3: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znaleziono %spottedErrors% z %numberOfErrors% błędów.',
			ro: 'Ați găsit %spottedErrors% din %numberOfErrors% erori.'
		},
	},

	/* Module - talk to facilitator popup */
	talkToFacilitatorPopup: {
		text: {
			da: 'Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.',
			en: 'You have had too many errors in this module. Talk to your facilitator about how to proceed.',
			pl: 'W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o dalszym postępowaniu.',
			ro: 'Ați avut prea multe erori în acest modul. Discutați cu facilitatorul despre cum să continuați.'
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			ro: 'Bine'
		}
	},

	/* Module - locked popup */
	moduleLockedPopup: {
		text: {
			da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.
				<br /><br />Modulet er låst indtil %date%.`,
			en: `You have had too many errors in this module. Talk to your facilitator about how to proceed. 
				<br /><br />This module will be locked until %date%`,
			pl: `W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o dalszym postępowaniu.
				<br /><br />Ten moduł będzie zablokowany do dnia %date%`,
			ro: `Ați avut prea multe erori în acest modul. Discutați cu facilitatorul despre cum să continuați.
				<br /><br />Acest modul va fi blocat până la %date%`
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			ro: 'Bine'
		}
	}
};

module.exports = {
	playerUiTexts
};