const languagesData = [
	{
		id: 'da',
		title: {
			da: 'Dansk', 
			en: 'Dansk', // language names are always in their own language
		},
		localization: 'da-DA'
	},
	{
		id: 'en',
		title: {
			da: 'English',
			en: 'English',
		},
		localization: 'en-GB'
	},
];

export {languagesData};